import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomFormValidators {
  static urlPattern = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;

  static streetValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string | null;
    const regex = /^[a-zA-Z0-9\s\-,'"&\\/]+$/;

    if (!value?.trim())
      return null;

    if (!regex.test(value))
      return { 'streetInvalid': { value } };

    return null;
  }

  static suburbValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;

    if (!value?.trim()) {
      return null;
    }

    const words: string[] = value?.trim()?.split(/\s+/);

    for (const word of words) {
      if (!/^[a-zA-Z]{2,}$/.test(word)) {
        return { 'suburbInvalid': { value } };
      }
    }

    if (value.match(/\s{2,}/)) {
      return { 'suburbInvalid': { value } };
    }

    return null;
  }

  static postCodeValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;
    const regex = /^[0-9]*$/gm;

    if (!value?.trim())
      return null;

    return regex.test(value) ? null : { 'postCodeInvalid': { value } };
  }

  static valueMatchValidator(matchValue: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value === matchValue ? null : { valueMismatch: true };
    };
  }

  static directorNameValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string[];
    const startsWithDigitRegex = /^\d/;

    for (const fullName of value) {
      const parts = fullName.split(' ');
      for (const part of parts) {
        if (startsWithDigitRegex.test(part.trim())) {
          return { 'startsWithDigit': true };
        }
      }
    }

    return null;
  }

  static directorFullNameValidator(control: AbstractControl): ValidationErrors | null {
    if(!control.value) return null;

    const value = control.value as string;
    const digitsRegex = /[0-9]/gm;
    // const startsWithUppercaseRegex = /^[A-Z][a-zA-Z0-9]{1,}(?: ([A-Z][a-zA-Z0-9]{1,})*){1,3}$/gm;

    const parts = value.split(' ').filter(part => part.trim() !== '');

    for (const part of parts) {
      if (part.trim().length === 1) {
        return { 'oneLetterPartName': true };
      }
    }

    if (digitsRegex.test(value.trim())) {
      return { 'nameWithDigit': true };
    }

    if(parts.length === 1) {
      return { 'doesNotHaveLastName': true };
    }

    return null;
  }

  static directorFirstNameValidator(control: AbstractControl): ValidationErrors | null {
    if(!control.value) return null;

    const value = control.value as string;
    const digitsRegex = /[0-9]/gm;
    const startsWithUppercaseRegex = /^[A-Z][a-zA-Z0-9]{1,}$/gm;

    const parts = value.split(' ').filter(part => part.trim() !== '');

    if (digitsRegex.test(value.trim())) {
      return { 'nameWithDigit': true };
    }

    if (!startsWithUppercaseRegex.test(value.trim())) {
      return { 'doesNotStartWithUppercase': true };
    }

    if(parts.length > 1) {
      return { 'oneWordName': true };
    }

    return null;
  }

  static directorOneLetterNameValidator(control: AbstractControl): ValidationErrors | null {
    if(!control.value) return null;

    const value = control.value as string[];

    for (const fullName of value) {
      const parts = fullName.split(' ');
      for (const part of parts) {
        if (part.trim().length === 1) {
          return { 'oneLetterPartName': true };
        }
      }
    }

    return null;
  }

  static maxLength(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = (control.value as string)?.toString()?.trim() ?? '';
      return value.length > max ? { maxLength: { requiredLength: max, actualLength: value.length } } : null;
    };
  }

  static minLength(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = (control.value as string)?.toString()?.trim() ?? '';
      return (value?.length && value?.length < min) ? { minLength: { requiredLength: min, actualLength: value.length } } : null;
    };
  }

  static onlyLettersAndNumbersAllowedValidator(control: AbstractControl): ValidationErrors | null {
    const pattern: RegExp = /^[a-zA-Z0-9 ]+$/;
    if (control.value && !pattern.test(control.value)) {
      return { onlyLettersAndNumbersAllowed: true };
    }
    return null;
  }

  static minArrayLength(min: number): ValidatorFn {
    return (control: AbstractControl): Record<string, unknown> | null => {
      const value = control.value as unknown[];
      if (Array.isArray(value) && value.length < min) {
        return { 'minArrayLength': { minLength: min, actualLength: value.length } };
      }
      return null;
    };
  }

  static maxArrayLength(max: number): ValidatorFn {
    return (control: AbstractControl): Record<string, unknown> | null => {
      const value = control.value as unknown[];
      if (Array.isArray(value) && value.length > max) {
        return { 'maxArrayLength': { maxLength: max, actualLength: value.length } };
      }
      return null;
    };
  }

  static url(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;
    if (!value || !value.trim())
      return null;

    return CustomFormValidators.urlPattern.test(value)
      ? null
      : { urlPattern: true };
  }

  static emailValidator(control: AbstractControl): ValidationErrors | null {
    if(!control.value) return null;

    const email = control.value as string;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const valid = emailPattern.test(email);
    return valid ? null : { invalidEmail: true };
  }

  static phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    if(!control.value) return null;

    const phone = (control.value as string)?.trim();

    // Check mobile number (starts with 04)
    if (phone.startsWith('04')) {
      return phone.length === 10 ? null : { invalidMobileNumber: true };
    }

    // Check landline number (starts with 02, 03, 07, or 08)
    if (['02', '03', '07', '08'].some(prefix => phone.startsWith(prefix))) {
      return phone.length === 10 ? null : { invalidLandlineNumber: true };
    }

    // Check '1300' number (starts with 1300)
    if (phone.startsWith('1300')) {
      return phone.length === 10 ? null : { invalid1300Number: true };
    }

    // Check '13' number (starts with 13, but not 130)
    if (phone.startsWith('13') && !phone.startsWith('130')) {
      return phone.length === 6 ? null : { invalid13Number: true };
    }

    return null;
  }

  static CorporateKeyValidator(control: AbstractControl): ValidationErrors | null {
    if(!control.value) return null;

    const value = (control.value as string)?.toString().trim();
    // Check if the value is numeric and has exactly 8 digits
    const isValid = /^[0-9]{8}$/.test(value);

    return isValid ? null : { invalidCorporateKey: true };
  };
}
