import { formatDate, NgClass } from '@angular/common';
import {
  booleanAttribute,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { NgbDateParserFormatter, NgbDatepicker, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import {
  DatePickerFormValidators,
  DatepickerHelper
} from '../../../../custom-form-validators/date-picker-form-validators';
import { setControlDisabled } from '../../../../functions/set-control-disabled';
import { FormSwitchButtonComponent } from '../form-switch-button/form-switch-button.component';
import { LinkComponent } from '../link/link.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';

@Component({
  selector: 'app-date-picker',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    NgbDatepicker,
    LinkComponent,
    NgbInputDatepicker,
    FormSwitchButtonComponent,
    ValidationErrorComponent,
    FormsModule,
  ],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss', './../input/input.component.scss'],
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  ngbDateCustomParserFormatter = inject(NgbDateParserFormatter);
  @Optional() protected ngControl = inject(NgControl);

  @Input() label = '';
  @Input() link = '';
  @Input() text = '';
  @Input() placeholder = 'Select Date';
  @Input() switchBtnText = '';
  @Input() helperText = '';
  @Input() shouldShowErrors = true;
  @Input({ transform: booleanAttribute }) showTodayButton = true;
  @Input({ transform: booleanAttribute }) readonly = false;
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) validationError = false;
  @Input({ transform: booleanAttribute }) checkedState = false;
  @Input({ transform: booleanAttribute }) warningState = false;
  @Input() customErrors: Record<string, string> = { ... DatePickerFormValidators.errorMessages };
  @Input() smallWidth = false;
  @Input() isRequired = true;
  @Input() useNullValueIfInvalid = true;

  @Input() minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };
  @Input() maxDate: NgbDateStruct = DatepickerHelper.getDefaultMaxDateStruct();

  @Output() selectDate = new EventEmitter<Date>();
  @Output() switchBtnClick = new EventEmitter<boolean>();
  @Output() input = new EventEmitter<string | null>();
  @Output() blur = new EventEmitter<void>();

  readonly mask = '__/__/____';
  today = DatepickerHelper.getTodayStruct();
  #value: string | null = null;

  datepickerControl = new FormControl<NgbDateStruct | null>(null);

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.isRequired)
      this.datepickerControl.addValidators([Validators.required]);
    this.datepickerControl.addValidators([DatePickerFormValidators.ngbDateStructBetweenValidator(this.minDate, this.maxDate)]);

    if(this.customErrors['tooSmallDate']) {
      this.customErrors['tooSmallDate'] = this.customErrors['tooSmallDate']
        + ': ' + formatDate(DatepickerHelper.getDateFromStruct(this.minDate), 'dd MMM yyyy', 'en');
    } else if(this.customErrors['tooBigDate']) {
      this.customErrors['tooBigDate'] = this.customErrors['tooBigDate']
        + ': ' + formatDate(DatepickerHelper.getDateFromStruct(this.maxDate), 'dd MMM yyyy', 'en');
    }

    this.control?.addValidators(((): ValidationErrors | null => {
        if (this.datepickerControl.invalid) {
          return this.datepickerControl.errors;
        }

        return null;
      })
    );
  }

  onInput(event: any): void {
    this.input.emit(event.data as string | null);
    let maskedValue = '';
    let value = event.target.value;
    const cursorPosition = this.mask.charAt(event.target.selectionStart) === '_'
      ? event.target.selectionStart
      : event.target.selectionStart + 1;

    // Remove non-digit characters
    value = value?.replace(/\D/g, '');

    const isUserDeleteCharacter = event.data === null && (event.inputType === 'deleteContentBackward' || event.inputType === 'deleteContentForward');
    if (isUserDeleteCharacter) {
      this.value = event.target.value;
    } else {
      // Apply mask only if user is writing a character
      maskedValue = this.applyMask(value);

      // Update input value and cursor position
      this.value = maskedValue;
      event.target.value = maskedValue;
      event.target.setSelectionRange(cursorPosition, cursorPosition);
    }

    this.control.markAsTouched();
    this.datepickerControl.markAsTouched();
  }

  onBlurInput(): void {
    this.blur.emit();
    this.onTouched();
  }

  onDateSelect(newDate: NgbDateStruct) {
    this.value = this.ngbDateCustomParserFormatter.format(newDate);
    this.datepickerControl.patchValue(newDate);
    this.control.patchValue(newDate);
    this.onChange(DatepickerHelper.getDateFromStruct(newDate));
    this.selectDate.emit(DatepickerHelper.getDateFromStruct(newDate));
  }

  setTodaysDate() {
    if (!this.shouldSetTodayButton)
      return;

    this.value = this.ngbDateCustomParserFormatter.format(this.today);
    this.selectDate.emit(DatepickerHelper.getDateFromStruct(this.today));
  }

  onClickBtn(): void {
    this.switchBtnClick.emit(true);
  }

  get control(): FormControl {
    // this control is only type of (Date | null)
    return this.ngControl.control as FormControl;
  }

  get value(): string | null {
    return this.#value;
  }

  set value(value: string) {
    const dateParts = value.trim().split('-');
    if(dateParts.length > 1) {
      this.#value = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    } else {
      this.#value = value;
    }

    this.parseForControl();
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  writeValue(value: NgbDateStruct | Date | string | null): void {
    if (value instanceof Date) {
      this.value = this.ngbDateCustomParserFormatter.format({
        year: value.getFullYear(),
        month: value.getMonth() + 1,
        day: value.getDate()
      });
    } else if (typeof value === 'string') {
      this.value = value;
      this.parseForControl();
    } else {
      this.value = this.ngbDateCustomParserFormatter.format(value);
    }
  }

  applyMask(value: string): string {
    let maskedValue = '';
    let index = 0;
    value = value.replace(/\D/g, '');

    for (let i = 0; i < this.mask.length; i++) {
      if (this.mask.charAt(i) === '_') {
        if (index < value.length) {
          maskedValue += value.charAt(index++);
        } else {
          // TODO: replace with space or something else
          maskedValue += '_';
        }
      } else {
        maskedValue += this.mask.charAt(i);
      }
    }

    return maskedValue;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    setControlDisabled(this.datepickerControl, isDisabled);
  }

  get shouldSetTodayButton(): boolean {
    return this.minDate.year <= this.today.year && this.today.year <= this.maxDate.year
      && this.minDate.month <= this.today.month && this.today.month <= this.maxDate.month
      && this.minDate.day <= this.today.day && this.today.day <= this.maxDate.day;
  }

  private parseForControl(): void {
    const parsedValue = this.ngbDateCustomParserFormatter.parse(this.value + '');
    this.datepickerControl.setValue(parsedValue);

    if (parsedValue && parsedValue.year && parsedValue.month >= 0) {
      if (this.datepickerControl.valid || this.useNullValueIfInvalid) {
        this.onChange(DatepickerHelper.getDateFromStruct(parsedValue));
      } else {
        this.onChange(null);
      }
    } else {
      this.onChange(null);
    }
  }
}
